<template>
	<div class="" style="height: 100%;width: 100%;">
		<router-view></router-view>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="scss" scoped>
	
	
	
</style>